<template>
  <div class="bap-container">
    <!-- Header -->
    <header class="header-container">
      <div class="img-container">
        <img
          src="../assets/img/logo.png"
          alt="logo"
        >
      </div>
      <nav class="nav-container">
        <ul class="nav-list">
          <li><a href="/">Home</a></li>
          <li>
            <a href="/traitranks">TraitRanks</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://twitter.com/bullsapesproj"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_16_96)">
                  <path
                    d="M7.86475 22.6563C17.2964 22.6563 22.4565 14.8403 22.4565 8.06447C22.4565 7.84474 22.4517 7.62013 22.4419 7.40041C23.4457 6.67447 24.312 5.7753 25 4.74513C24.0651 5.16107 23.0725 5.43272 22.0562 5.5508C23.1263 4.90931 23.9276 3.90156 24.3115 2.71437C23.3047 3.31103 22.2037 3.73192 21.0557 3.959C20.2821 3.13707 19.2594 2.59286 18.1455 2.4105C17.0316 2.22814 15.8887 2.41779 14.8935 2.95013C13.8982 3.48247 13.106 4.32785 12.6394 5.35556C12.1728 6.38328 12.0577 7.53609 12.312 8.63576C10.2734 8.53346 8.2791 8.00389 6.45831 7.08138C4.63753 6.15888 3.03093 4.86403 1.74268 3.28078C1.08792 4.40966 0.887559 5.74549 1.18232 7.01679C1.47709 8.28809 2.24486 9.39945 3.32959 10.125C2.51524 10.0992 1.71872 9.87991 1.00586 9.48537V9.54884C1.00513 10.7335 1.41469 11.8819 2.16491 12.7987C2.91513 13.7156 3.95971 14.3444 5.12109 14.5781C4.36672 14.7845 3.57498 14.8146 2.80713 14.666C3.13485 15.6849 3.77247 16.576 4.63101 17.215C5.48955 17.854 6.52617 18.209 7.59619 18.2305C5.77961 19.6574 3.5356 20.4314 1.22559 20.4277C0.815924 20.4271 0.406666 20.402 0 20.3526C2.34673 21.8581 5.07659 22.6577 7.86475 22.6563Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_16_96">
                    <rect
                      width="25"
                      height="25"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://discord.com/invite/bullsandapesproject"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.1636 4.32874C19.5701 3.59762 17.8614 3.05895 16.0749 2.75044C16.0424 2.74449 16.0099 2.75937 15.9931 2.78913C15.7733 3.17997 15.5299 3.68987 15.3595 4.09064C13.4379 3.80297 11.5262 3.80297 9.64408 4.09064C9.47359 3.68096 9.22134 3.17997 9.0006 2.78913C8.98384 2.76036 8.95134 2.74548 8.9188 2.75044C7.13324 3.05797 5.42454 3.59663 3.83014 4.32874C3.81634 4.33469 3.80451 4.34462 3.79666 4.35751C0.555616 9.19955 -0.33224 13.9226 0.103313 18.5871C0.105283 18.6099 0.118094 18.6317 0.135831 18.6456C2.27418 20.2159 4.34553 21.1693 6.37842 21.8012C6.41095 21.8111 6.44542 21.7992 6.46613 21.7724C6.94701 21.1157 7.37567 20.4233 7.7432 19.6951C7.76489 19.6525 7.74419 19.6019 7.69986 19.585C7.01993 19.3271 6.3725 19.0126 5.74972 18.6555C5.70046 18.6267 5.69651 18.5563 5.74183 18.5225C5.87289 18.4243 6.00398 18.3222 6.12912 18.219C6.15176 18.2002 6.18331 18.1962 6.20993 18.2081C10.3013 20.0761 14.7308 20.0761 18.7739 18.2081C18.8005 18.1952 18.8321 18.1992 18.8557 18.218C18.9809 18.3212 19.1119 18.4243 19.244 18.5225C19.2893 18.5563 19.2863 18.6267 19.2371 18.6555C18.6143 19.0196 17.9669 19.3271 17.286 19.584C17.2416 19.6009 17.2219 19.6525 17.2436 19.6951C17.619 20.4223 18.0477 21.1147 18.5197 21.7714C18.5394 21.7992 18.5749 21.8111 18.6074 21.8012C20.6501 21.1693 22.7215 20.2159 24.8598 18.6456C24.8786 18.6317 24.8904 18.6109 24.8924 18.588C25.4136 13.1954 24.0193 8.5111 21.1961 4.3585C21.1892 4.34462 21.1774 4.33469 21.1636 4.32874ZM8.35419 15.7469C7.12239 15.7469 6.10743 14.616 6.10743 13.2272C6.10743 11.8383 7.10271 10.7074 8.35419 10.7074C9.61549 10.7074 10.6206 11.8483 10.6009 13.2272C10.6009 14.616 9.60563 15.7469 8.35419 15.7469ZM16.6612 15.7469C15.4294 15.7469 14.4145 14.616 14.4145 13.2272C14.4145 11.8383 15.4097 10.7074 16.6612 10.7074C17.9225 10.7074 18.9276 11.8483 18.908 13.2272C18.908 14.616 17.9225 15.7469 16.6612 15.7469Z"
                  fill="white"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </header>

    <!-- Hero Section -->
    <section class="bap-container__hero">
      <div class="bap-container__hero__content">
        <h1 class="bap-container__hero__content__title">
          B.A.P. Nation
        </h1>
        <p class="bap-container__hero__content__desc">
          Welcome back to the B.A.P. Beat, our weekly newsletter for the Bulls
          and Apes Project Community.
        </p>
        <p class="bap-container__hero__content__desc">
          What a week! We got to see Gui mint a God Bull, The Trait Groups held
          a MegaSpace, we hosted some amazing people on our different shows for
          you, and so much more.
        </p>
      </div>
      <div class="bap-container__hero__image">
        <img
          id="hero-main-image"
          src="../assets/img/bap-beat/undead_23_nobg_.0011_720.png"
          alt="B.A.P. Beat"
        >
      </div>
    </section>

    <!-- Community Section  -->

    <div class="bap-container__community bap-section">
      <section
        id="community-header"
        class="bap-container__section__header"
      >
        <h1>COMMUNITY</h1>
      </section>

      <!-- Topic -->
      <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">
          Oh My God Bull
        </h2>
        <div class="bap-container__topic__body">
          <p>
            Did you catch the special edition of The Weekly Fix Live this past
            Thursday? We know how much you love hearing from the executive team
            so Gary and Chris hosted one of our Founders, Gui, to talk about why
            he has waited to mint anything with his OG Bull collection. We got
            to watch as he walked us through the minting process of Incubators,
            Teens, Merger Orbs, and all the way to a God Bull. And then he
            shocked us all by letting us know if the floor price of the OG Bull
            collection gets to .25 ETH he will raffle his first God Bull off to
            one lucky buyer. It was extremely exciting to watch it all happen at
            once. Go watch the replay at
            <a href="https://youtu.be/XhyCYThWaic">https://youtu.be/XhyCYThWaic</a>
            and show your friends how easy it is to get free mints with B.A.P.
            just by holding OG Bulls.
          </p>
        </div>
      </section>
    </div>

    <!-- Concert Section -->

    <div class="bap-container__contests bap-section">
      <section class="bap-container__section__header">
        <h1>CONTESTS</h1>
      </section>

      <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">
          Space Wars
        </h2>
        <p class="bap-container__topic__body">
          Yes, your favorite contest is back! We announced that we will be
          running this super fun event again. It will be running from Saturday
          8/27 to Thursday 9/1. For more details on how you can participate,
          please check our discord for the announcement.
        </p>
      </section>

      <!-- <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">Trait Group Contest</h2>
        <div class="bap-container__topic__body">
          <p>
            We announced a new contest for our Certified Trait Groups. We are
            having a Discord Invite Contest that will be running from Saturday
            8/27 to Thursday 9/1. Get your trait group involved today! Head to
            our discord for more details.
          </p>
        </div>
      </section> -->
    </div>

    <!-- Media Section -->

    <div class="bap-container__media bap-section">
      <section class="bap-container__section__header">
        <h1>MEDIA</h1>
      </section>

      <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">
          Tokenomics Tuesday
        </h2>
        <p class="bap-container__topic__body">
          What a great time! Tankman broke it all down for us on how our
          ecosystem works with the METH token, how to get your free mints using
          the METH, what each of our utility tokens are, as well as what each of
          the new God Bull utilities are and how they work. We also talked about
          trait groups and we were lucky enough to have a few of the trait group
          leaders hop on stage and speak with us.
          <strong>TheW1tchD0ct0r</strong> even threw out a little alpha about a
          possible new trait group coming out! Go check out the replay from our
          Twitter account.
        </p>
        <!-- <img
          class="img-rounded"
          src="../assets/img/bap-beat/air_god_bull_26_nobg_.0010_720.png"
        /> -->
      </section>

      <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">
          The Weekly Fix Live
        </h2>
        <p class="bap-container__topic__body">
          Did you catch this weeks’ livestream? Gary and Chris nailed it again.
          They had special guest musical artist Rae Isla on layin down some
          great music and talking about how she is using her music and interests
          to bring new people into the NFT space. They also had String on to
          talk about her Chubbiverse. This creative woman is making waves in the
          NFT space and has valuable insights you need to hear. Two amazing
          women killing it in their own ways to make the Web3 space better for
          all of us. If you missed it go check out the replay at
          <a href="https://youtu.be/DYevOd00-tI">https://youtu.be/DYevOd00-tI</a>
        </p>
      </section>

      <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">
          The Daily Drop
        </h2>
        <p class="bap-container__topic__body">
          It was another fun week with HERCrypto and CarBar. They both started a
          little slow after a long weekend but quickly got back into the groove.
          They had a nice surprise drop in from Sammy Arriaga on Tuesday’s show
          where he previewed some different versions of his currently minting
          song. They discussed the roles of artificial intelligence in the
          musical and influencer industries. If you missed it, be sure to head
          to the Daily Drop Twitter page to listen to the recording. There is
          always breaking NFT/Web3 news they are sharing during the Daily Drop
          so make sure you’re tuning in.
          <strong>Monday - Friday 11:30a CST</strong>
        </p>

        <!-- <img
          class="img-rounded"
          src="../assets/img/bap-beat/OGBull.05509.webp"
        /> -->
      </section>

      <section class="bap-container__topic">
        <h2 class="bap-container__topic__title">
          Tech Updates
        </h2>
        <div class="bap-container__topic__body">
          Over the past couple weeks we have added some great new features to
          our TraitRanks tool for you. We’ve added new search options for ETH
          addresses and floor gaps, upgraded the auto-zoom slider, and even
          added the ETH address of the Bull holder right on the front of the
          card! Go check out the full announcement and details in our Discord at
          <a href="https://discord.gg/hytuNvQR">https://discord.gg/hytuNvQR</a>
        </div>
      </section>
    </div>

    <!-- Announcements -->
    <div class="bap-container__announcement" />

    <!-- Calendar -->
    <!-- <section class="bap-container__topic__calendar">
      <img
        src="../assets/img/bap-beat/bulls___apes_-_calendar_august_22-28__1_.jpg"
      />
    </section> -->
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { contents } from "../config/data.js";

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      contents,
    };
  },

  mounted() {
    const isMobile = window.innerWidth < 1000;
    if (!isMobile) {
      this.animateTopicBody();
      this.animationHero();
      this.animateTopicHeaders();
    }
  },

  methods: {
    animationHero: () => {
      const isMobile = window.innerWidth < 1000;

      if (isMobile) return;

      const heroTimeline = gsap.timeline();

      heroTimeline
        .from(".bap-container__hero__content__title", {
          duration: 1,
          y: -50,
          opacity: 0,
          ease: "power2.inOut",
        })
        .from(".bap-container__hero__content__desc", {
          duration: 1,
          y: -50,
          opacity: 0,
          ease: "power2.inOut",
        });

      gsap.to(".bap-container__hero__image", {
        borderRadius: "50%",
        y: "100%",
        scale: 0.5,
        x: "10%",

        scrollTrigger: {
          trigger: ".bap-container__hero",
          start: "top top",
          scrub: true,
          //   markers: true,
          id: "hero-main-image-scroll-trigger",
        },
      });
    },
    animateTopicHeaders: () => {
      const isMobile = window.innerWidth < 1000;

      if (isMobile) return;

      const headers = gsap.utils.toArray(".bap-container__section__header");

      headers.forEach((header) => {
        // const text = header.querySelector("h1");
        gsap.to(header, {
          x: "-35%",
          duration: 1,
          position: "sticky",
          top: "0px",
          fontSize: "3rem",
          scale: 0.3,
          color: "rgb(225, 109, 45)",

          ease: "power2.inOut",
          scrollTrigger: {
            trigger: header,
            start: "top bottom",
            scrub: true,
          },
        });
      });
    },

    animateTopicBody: () => {
      const isMobile = window.innerWidth < 1000;

      if (isMobile) return;

      const topics = gsap.utils.toArray(".bap-container__topic");

      topics.forEach((topic) => {
        gsap.to(topic, {
          x: "-100%",
          duration: 1,
          opacity: 0,
          ease: "power2.inOut",
          scrollTrigger: {
            trigger: topic,
            start: "top top",
            scrub: true,
          },
        });
      });
    },
  },
};
</script>
